<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div class="flex-shrink-0 h-10 w-10">
          <avatar
            :attendee="attendee"
          />
        </div>
        <div class="ml-4">
          <div class="text-sm font-medium text-gray-900">
            {{ attendee.fullname }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="text-sm text-gray-900">
        {{ attendee.company }}
      </div>
      <div class="text-sm text-gray-500">
        {{ attendee.country }}
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ interests }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
      <a-button
        size="sm"
        @click="onContact"
      >
        Contact
      </a-button>
    </td>
  </tr>
</template>

<script>
import { map, join } from '@/utils/lodash';
import Avatar from './avatar.vue';
export default {
  components: {
    Avatar
  },
  props: {
    attendee: {
      type: Object,
      required: true
    }
  },
  emits: ['contact'],
  computed: {
    interests() {
      return join(map(this.attendee.business, 'title'), ', ');
    }
  },
  methods: {
    onContact() {
      this.$emit('contact', this.attendee);
    }
  }
};
</script>