<template>
  <a-modal
    open
    :title="title"
    @close="onClose"
  >
    <div class="mb-4">
      <div class="col-span-12 sm:col-span-3">
        <a-label
          for="message" 
        >
          Message
        </a-label>
        <a-textarea
          id="message"
          v-model="message"
          name="message"
          rows="5"
          placeholder="Enter your message here..."
          required
        />
      </div>
    </div>
    <div class="mb-4">
      <div class="col-span-12 sm:col-span-3 text-gray-600 text-sm">
        I acknowledge that my email address will be shared with attendee by using this form.
      </div>
    </div>
    <template #footer>
      <div class="w-full flex justify-end">
        <a-button
          :loading="loading"
          size="sm"
          class="w-full sm:w-2/12"
          @click="onSubmit"
        >
          Submit
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { error } from '@/utils/helpers';
import { mapActions } from 'vuex';
import { VuelidateMixin } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
  mixins: [VuelidateMixin],
  props: {
    attendee: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data: () => ({
    loading: false,
    message: ''
  }),
  validations: {
    message: {
      required
    }
  },
  computed: {
    title() {
      return `Contact ${this.attendee.company}`;
    }
  },
  methods: {
    ...mapActions('attendees', ['email']),
    onClose() {
      this.$v.$reset();
      this.message = '';
      this.loading = false;
      this.$emit('close');
    },
    async onSubmit() {
      this.$v.$touch();

      this.loading = true;
      const form = {
        message: this.message,
        id: this.attendee.id
      };

      try {
        await this.email(form);
        this.$notify('success', 'Message Sent.');
        this.onClose();
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>