<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Company
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Business Interests
                </th>
                <th
                  scope="col"
                  class="relative px-6 py-3"
                >
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <attendee
                v-for="attendee in attendees"
                :key="attendee.id"
                :attendee="attendee"
                @contact="onContact"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <contact-modal
      v-if="isContactVisible"
      :attendee="currentAttendee"
      @close="onCloseModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import Attendee from '@/components/dashboard/attendees/table-row.vue';
import ContactModal from '@/components/dashboard/attendees/contact-modal.vue';
export default {
  components: {
    Attendee,
    ContactModal
  },
  data: () => ({
    currentAttendee: {},
    isContactVisible: false
  }),
  computed: {
    ...mapGetters('conferences', ['current']),
    ...mapState('attendees', ['attendees', 'loaded'])
  },
  watch: {
    current: {
      immediate: true,
      handler: 'onCurrentConferenceChange'
    }
  },
  beforeUnmount() {
    this.clear();
  },
  methods: {
    ...mapActions('attendees', ['all']),
    ...mapMutations('attendees', { clear: 'CLEAR_ATTENDEES' }),
    async onCurrentConferenceChange() {
      if (this.current) {
        await this.all();
      }
    },
    onContact(attendee) {
      this.currentAttendee = attendee;
      this.isContactVisible = true;
    },
    onCloseModal() {
      this.isContactVisible = false;
      this.currentAttendee = {};
    }
  }
};
</script>